import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import TechnehireHeroSection from '../sections/caseStudies/techneHire/TechnehireHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import TechnehireSection1 from '../sections/caseStudies/techneHire/TechnehireSection1';
import TechnehireSection2 from '../sections/caseStudies/techneHire/TechnehireSection2';
import TechnehireSection3 from '../sections/caseStudies/techneHire/TechnehireSection3';
import TechnehireSection4 from '../sections/caseStudies/techneHire/TechnehireSection4';
import TechnehireSection5 from '../sections/caseStudies/techneHire/TechnehireSection5';
import TechnehireSection6 from '../sections/caseStudies/techneHire/TechnehireSection6';
import TechnehireSection7 from '../sections/caseStudies/techneHire/TechnehireSection7';
import TechnehireSection8 from '../sections/caseStudies/techneHire/TechnehireSection8';
import TechnehireSection9 from '../sections/caseStudies/techneHire/TechnehireSection9';
import TestimonialSection from '../sections/TestimonialSection';

const TechneHire = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudyTechnehirePageACF;
  console.log(mainData);
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');
  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger)
  //   setTimeout(() => {
  //     gsap.set(sectionRef.current, {
  //       scrollTrigger: {
  //         trigger: '.bariningcamp-case-study .section-2',
  //         start: "top 60%",
  //         end: "bottom 60%",
  //         ease: "ease",
  //         scrub: true,
  //         markers: false,
  //         onEnter: () => setBgTransition('brainingcamp-bg'),
  //         onLeave: () => setBgTransition(''),
  //         onEnterBack: () => setBgTransition('brainingcamp-bg'),
  //         onLeaveBack: () => setBgTransition(''),
  //       }
  //     });
  //   }, 1000);
  // }, []);
  return (
    <Layout headerClass="white-header">
      <Seo title="TechneHire" />
      <div className={"technehire-case-study page-wrapper " + bgTransition} id="technehire-casestudy-page-wrapper" ref={sectionRef}>
        <TechnehireHeroSection 
            preTitle={parse(mainData.technehireHeroSectionPretitle)}
            mainTitle={parse(mainData.technehireHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.technehireHeroSectionImage1x.sourceUrl,
                altText: mainData.technehireHeroSectionImage1x.altText,
                width: mainData.technehireHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.technehireHeroSectionImage2x.sourceUrl,
                altText: mainData.technehireHeroSectionImage2x.altText,
                width: mainData.technehireHeroSectionImage2x.width,
              }
            }
        />
        <ChallengesSection
          companyInfoList={mainData.technehireChallengeSectionCompanyInfoList}
          challengesList={mainData.technehireChallengeSectionChallangesList}
        />
        <TechnehireSection1 
          mainTitle={parse(mainData.technehireSection1Title)}
          mainDescription={parse(mainData.technehireSection1Description)}
          image1x={
            {
              sourceUrl: mainData.technehireSection1Image1x.sourceUrl,
              altText: mainData.technehireSection1Image1x.altText,
              width: mainData.technehireSection1Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.technehireSection1Image2x.sourceUrl,
              altText: mainData.technehireSection1Image2x.altText,
              width: mainData.technehireSection1Image2x.width,
            }
          }
        />
        <TechnehireSection2 
          mainTitle={parse(mainData.technehireSection2Title)}
          mainDescription={parse(mainData.technehireSection2Description)}
          image1x={
            {
              sourceUrl: mainData.technehireSection2Image1x.sourceUrl,
              altText: mainData.technehireSection2Image1x.altText,
              width: mainData.technehireSection2Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.technehireSection2Image2x.sourceUrl,
              altText: mainData.technehireSection2Image2x.altText,
              width: mainData.technehireSection2Image2x.width,
            }
          }
          imageslist={mainData.technehireSection2ImageList}
        />
        <TechnehireSection3 
          mainTitle={parse(mainData.technehireSection3Title)}
          mainDescription={parse(mainData.technehireSection3Description)}
          image1x={
            {
              sourceUrl: mainData.technehireSection3Image1x.sourceUrl,
              altText: mainData.technehireSection3Image1x.altText,
              width: mainData.technehireSection3Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.technehireSection3Image2x.sourceUrl,
              altText: mainData.technehireSection3Image2x.altText,
              width: mainData.technehireSection3Image2x.width,
            }
          }
        />
        <TechnehireSection4 
          mainTitle={parse(mainData.technehireSection4Title)}
          mainDescription={parse(mainData.technehireSection4Description)}
          imageslist={mainData.technehireSection4ImageList}
        />
        <TechnehireSection5 
          mainTitle={parse(mainData.technehireSection5Title)}
          imageslist={mainData.technehireSection5ImageList}
        />
        <TechnehireSection6 
          image1x={
            {
              sourceUrl: mainData.technehireSection6Image1x.sourceUrl,
              altText: mainData.technehireSection6Image1x.altText,
              width: mainData.technehireSection6Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.technehireSection6Image2x.sourceUrl,
              altText: mainData.technehireSection6Image2x.altText,
              width: mainData.technehireSection6Image2x.width,
            }
          }
        />
        <TechnehireSection7 
          mainTitle={parse(mainData.technehireSection7Title)}
          imageslist={mainData.technehireSection7ImageList}
        />
        <TechnehireSection8 
          mainTitle={parse(mainData.technehireSection8Title)}
          image1x={
            {
              sourceUrl: mainData.technehireSection8Image1x.sourceUrl,
              altText: mainData.technehireSection8Image1x.altText,
              width: mainData.technehireSection8Image1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.technehireSection8Image2x.sourceUrl,
              altText: mainData.technehireSection8Image2x.altText,
              width: mainData.technehireSection8Image2x.width,
            }
          }
        />
        <TechnehireSection9 
          mainTitle={parse(mainData.technehireSection9Title)}
          imageslist={mainData.technehireSection9ImageList}
          imageslist2={mainData.technehireSection9ImageList2}
        />
        {/* <TestimonialSection 
          mainTitle={parse(mainData.technehireTestimonialSectionTitle)}
          message={parse(mainData.technehireTestimonialSectionDescription)}
          name={parse(mainData.technehireTestimonialSectionName)}
          designation={parse(mainData.technehireTestimonialSectionDesignation)}
          headshot1x={
            {
              sourceUrl: mainData.technehireTestimonialSectionHeadshot1x.sourceUrl,
              altText: mainData.technehireTestimonialSectionHeadshot1x.altText,
              width: mainData.technehireTestimonialSectionHeadshot1x.width,
            }
          }
          headshot2x={
            {
              sourceUrl: mainData.technehireTestimonialSectionHeadshot2x.sourceUrl,
              altText: mainData.technehireTestimonialSectionHeadshot2x.altText,
              width: mainData.technehireTestimonialSectionHeadshot2x.width,
            }
          }
          logo={
            {
              sourceUrl: mainData.technehireTestimonialSectionLogo.sourceUrl,
              altText: mainData.technehireTestimonialSectionLogo.altText,
              width: mainData.technehireTestimonialSectionLogo.width,
            }
          }
        /> */}
      </div>
    </Layout>
  )
}



export const TechnehireQuery = graphql`

{
  allWpPage(filter: {slug: {eq: "technehire"}}) {
      nodes {
        caseStudyTechnehirePageACF {
            technehireHeroSectionPretitle
            technehireHeroSectionTitle
            technehireHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            technehireHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            technehireChallengeSectionCompanyInfoList {
              title
              description
            }
            technehireChallengeSectionChallangesList {
              title
              description
            }
            technehireSection1Title
            technehireSection1Description
            technehireSection1Image1x {
              altText
              sourceUrl
              width
            }
            technehireSection1Image2x {
              altText
              sourceUrl
              width
            }

            technehireSection2Title
            technehireSection2Description
            technehireSection2Image1x {
              altText
              sourceUrl
              width
            }
            technehireSection2Image2x {
              altText
              sourceUrl
              width
            }
            technehireSection2ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            technehireSection3Title
            technehireSection3Description
            technehireSection3Image1x {
              altText
              sourceUrl
              width
            }
            technehireSection3Image2x {
              altText
              sourceUrl
              width
            }
            technehireSection4Title
            technehireSection4Description
            technehireSection4ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            technehireSection5Title
            technehireSection5ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            technehireSection6Image1x {
              altText
              sourceUrl
              width
            }
            technehireSection6Image2x {
              altText
              sourceUrl
              width
            }

            technehireSection7Title
            technehireSection7ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            technehireSection8Title
            technehireSection8Image1x {
              altText
              sourceUrl
              width
            }
            technehireSection8Image2x {
              altText
              sourceUrl
              width
            }

            technehireSection9Title
            technehireSection9ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            technehireSection9ImageList2 {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }

            technehireTestimonialSectionTitle
            technehireTestimonialSectionDescription
            technehireTestimonialSectionHeadshot1x {
              altText
              sourceUrl
              width
            }
            technehireTestimonialSectionHeadshot2x {
              altText
              sourceUrl
              width
            }
            technehireTestimonialSectionName
            technehireTestimonialSectionDesignation
            technehireTestimonialSectionLogo {
              altText
              sourceUrl
              width
            }
        }
      }
    }
}

`

export default TechneHire